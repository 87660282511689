<template>
    <div>
        <component
            v-bind:is="componentName"
            :pageData="pageData"
            v-if="componentName"
        />
        <template v-else-if="!componentName">
            <section class="post">
                <div class="container">
                    <h1>{{ pageData.meta_title }}</h1>
                    <p v-html="pageData.content"></p>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Static",
    data() {
        return {
            pageData: {},
            isNotFound: false
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.getPage();
            },
            deep: true
        }
    },
    created() {
        this.getPage();
    },
    computed: {
        componentName: function() {
            if (this.pageData && this.pageData.component_name) {
                return this.pageData.component_name.replace(".vue", "");
            }
            return null;
        },
        pageLanguage: function() {
            return this.$store.state.defaultStore.pageLanguage;
        }
    },
    methods: {
        getPage() {
            const self = this;
            ApiService.get(
                "/page" + (self.$route.path !== "/" ? self.$route.path : "")
            )
                .then(response => {
                    self.pageData = response.data.data;
                    self.metaCreation(self.pageData);
                })
                .catch(err => {
                    console.log(err);
                    self.$router.push({ name: "NotFound" });
                });
        }
    }
};
</script>

<style scoped></style>
